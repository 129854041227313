import '../Styles/Header.css';
import logo from '../Media/Taaza-logo.svg'
import profIcon from '../Media/profile-icon.png'
import { NavLink } from 'react-router-dom';
import { useEffect } from 'react';
import axint from '../ax_interceptor';

export default function Header(){

    useEffect(()=>{
        // *** WORK-AROUND (CHANGE LATER IF POSSIBLE) ***
        // var profBtn = document.getElementById("prof-btn");
        // if(profBtn){
        //     var profMenu = document.getElementById("prof-menu");
        //     profBtn.addEventListener("mouseover",()=>{
        //         profMenu.style.display = "flex";
        //     });
        //     profBtn.addEventListener("mouseout",()=>{
        //         profMenu.style.display = "none";
        //     });
        //     profMenu.addEventListener("mouseover",()=>{
        //         profMenu.style.display = "flex";
        //     });
        //     profMenu.addEventListener("mouseout",()=>{
        //         profMenu.style.display = "none";
        //     });
        // }
        axint.post('/test/',
                {test: 'test success'},
                {headers: {'Content-Type': 'application/json'}},  
                {withCredentials: true})
            .then((res)=>{
                console.log(res.data);
            })
            .catch((err)=>{
                console.log(err);
            });
    });

    // const isLoggedin = !!localStorage.getItem("tan");

    function logout(){
        // axint.post('/logout/',
        //         {trn: localStorage.getItem('trn')},
        //         {headers: {'Content-Type': 'application/json'}},  
        //         {withCredentials: true})
        //     .then(()=>{
        //         localStorage.clear();
        //         window.location.href = '/login'
        //     })
        //     .catch((err)=>{
        //         console.log(err);
        //         alert("Unable to logout. Try again");
        //     });
    }

    function toggleHambgMenu(){
        var x = document.getElementById("hambg-menu");
        if (x.style.display === "flex") {
            x.style.display = "none";
        } else {
            x.style.display = "flex";
        }
    }

    // *** WORK-AROUND (CHANGE LATER IF POSSIBLE) ***
    window.addEventListener('resize',()=>{
        var x = document.getElementById("hambg-menu");
        if(window.innerWidth>768 && x.style.display === "flex"){
            x.style.display = "none";
        }
    });

    document.addEventListener('mousedown',(e)=>{
        var x = document.getElementById("hambg-menu");
        var y = document.getElementById("hambg-btn");
        if(!x.contains(e.target) && !y.contains(e.target)){
            x.style.display = "none";
        }
    })

    return(
        <div className='header-wrapper'>
            <div className='header-main-cont'>
                <div className='header-logo-cont'>
                    <a href='/'>
                        <img src={logo} alt='taazafoodz-logo'/>
                    </a>
                </div>
                <div className='header-navbar-cont'>
                    <NavLink to='/'>Home</NavLink>
                    <NavLink to='/order'>Order</NavLink>
                    <NavLink to='/contact-us'>Contact Us</NavLink>
                    <div className='nav-bar-space'></div>
                    {/* { isLoggedin ?
                        <button id='prof-btn'><img src={profIcon} alt='profile-icon'/></button>
                        :
                        <NavLink to='/login' id='login-btn'>Login ↩</NavLink>} */}
                </div>
                <div className='header-hambg-cont'>
                    <button id='hambg-btn' onClick={toggleHambgMenu}><span/><span/><span/></button>
                </div>
            </div>
            {/* <div className='prof-menu' id='prof-menu'>
                <NavLink to='/profile'>My profile</NavLink>
                <NavLink onClick={logout} id='navbar-logout'>Logout ↪</NavLink>
            </div> */}
            <div className='hambg-menu' id='hambg-menu'>
                {/* { isLoggedin ? 
                <NavLink to='/profile'>My profile</NavLink> : 
                <NavLink to='/login'>Login</NavLink>} */}
                <NavLink to='/'>Home</NavLink>
                <NavLink to='/order'>Order</NavLink>
                <NavLink to='/contact-us'>Contact Us</NavLink>
                {/* { isLoggedin ? <NavLink onClick={logout} id='hambg-logout'>Logout ↪</NavLink> : null} */}
            </div>
        </div>
    );
}