import '../Styles/Register.css';
import axios from 'axios';
import { useState } from 'react';
import signupBg from '../Media/AdobeStock_566538453.jpeg'

export default function Register(){

    const [creds,setCreds] = useState({});
    const [toolTips,setToolTips] = useState({});

    function submit(e){
        e.preventDefault();
        let {cpswd:_, ...actual_creds} = creds;
        axios.post("http://localhost:8000/register/",
                            actual_creds,
                            {headers:{"Content-Type":"application/json"}},
                            {withCredentials:true})
            .then((res)=>{
                window.location.href = "/login";
            })
            .catch((err)=>{
                console.log(err);
                alert("Try again");
            })
    }
    
    return(
        <div className='register-main-cont'>
            <img src={signupBg} alt='login-bg'/>
            <form onSubmit={submit} className='register-form'>
                <input name='fullname'
                type='text'
                placeholder='Name'
                value={creds.first_name || ""}
                onChange={(e)=>setCreds({...creds,first_name:e.target.value})}
                autoFocus required/>

                <input name='email'
                type='text'
                placeholder='Email'
                value={creds.email || ""}
                onChange={(e)=>setCreds({...creds,email:e.target.value})}
                required/>

                <input name='phone_num'
                type='text'
                placeholder='Mobile No.'
                value={creds.phone_num || ""}
                onChange={(e)=>{
                    if(!creds.phone_num) setCreds({...creds,phone_num:`(+1) ${e.target.value}`})
                    else setCreds({...creds,phone_num:e.target.value});}}/>
                
                <input name='password'
                type='password'
                placeholder='Password'
                value={creds.password || ""}
                onChange={(e)=>setCreds({...creds,password:e.target.value})}
                required/>

                <input name='cpassword'
                type='password'
                placeholder='Confirm Password'
                value={creds.cpswd || ""}
                onChange={(e)=>setCreds({...creds,cpswd:e.target.value})}
                required/>

                <input type='submit' value='Sign up'/>
            </form>
        </div>
    );
}