import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Header from './Components/Header';
import Home from './Components/Home';
import Order from './Components/Order';
import ContactUs from './Components/ContactUs';
import Login from './Components/Login';
import Register from './Components/Register';
import NoPage from './Components/NoPage';
import Menu from './Components/Menu';
// import { useState, useEffect } from 'react';
// import Loading from './Components/Loading';

function App() {

  // const [isLoading,setIsLoading] = useState(true);

  // useEffect(()=>{
  //   setTimeout(()=>{
  //     setIsLoading(false);
  //   },1000)
  // },[])

  return (
    // isLoading ?
    // <Loading/>:
    (<div className='app-main-cont'>
    <BrowserRouter>
      <Header/>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/order' element={<Order/>}/>
        <Route path='/contact-us' element={<ContactUs/>}/>
        <Route path='/login' element={<Login/>}/>
        <Route path='/register' element={<Register/>}/>
        <Route path='/menu' element={<Menu/>}/>
        <Route path='*' element={<NoPage/>}/>
      </Routes>
    </BrowserRouter>
    </div>)
  );
}

export default App;