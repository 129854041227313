import axios from "axios";

const axint = axios.create({
  baseURL: "http://localhost:8000/api",
});

axint.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('tan');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axint.interceptors.response.use(resp => resp, (error) => {
  const originalRequest = error.config;
  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    try{
        axios.post('http://localhost:8000/tknrefresh/', 
                        {refresh:localStorage.getItem('trn')},
                        { headers: {'Content-Type': 'application/json'}},
                        {withCredentials: true})
              .then((res)=>{
                  localStorage.setItem('tan', res.data.tan);
                  localStorage.setItem('trn', res.data.trn);
                  originalRequest.headers.Authorization = `Bearer ${res.data.access}`;
              })
              .catch((err)=>{
                console.log(err);
                //window.location.href = '/login'
              })
        return axios(originalRequest);
      }
    catch(err){
      console.log(err);
    }
  }
  return Promise.reject(error);
});

export default axint;