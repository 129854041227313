import '../Styles/ContactUs.css';
import instaIcon from '../Media/insta-icon.png'
import fbIcon from '../Media/fb-icon.png'

export default function ContactUs(){

    return(
        <div className='contactus-wrapper-cont'>
            <div className='contactus-main-cont'>
                <hr/>
                <div className='contactus-content-cont'>
                    <span className='contactus-title-cont'>Contact us<br/>at:</span>
                    <span className='contactus-num-cont'>(+1) 469 450 3105</span>
                </div>
            </div>
            <div className='contactus-social'>
                    <a href='https://www.instagram.com/taaza_foodtruck?igsh=YnB0dnpzYnM3aWEx' target="_blank" rel="noopener noreferrer">
                        <img src={instaIcon} alt='insta-icon'/>
                    </a>
                    <a href='https://www.facebook.com/profile.php?id=61558465851578' target="_blank" rel="noopener noreferrer">
                        <img src={fbIcon} alt='fb-icon'/>
                    </a>
            </div>
        </div>
    );
}