import '../Styles/NoPage.css';

export default function NoPage(){
    return (
        <div className='nopage-main-cont'>
            Page not found ☹️<br/><br/>
            Try your search again,<br/>
            or use the navigation above
        </div>
    );
}