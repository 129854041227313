import '../Styles/Home.css';
import logo from '../Media/Taaza-logo-cropped.png'
import pdf from '../Media/MenuCardTaaza.pdf'

export default function Home(){

    return(
        <div className='home-main-cont'>
            <div className='home-content-cont'>
                <div className='home-logo-cont'>
                    <img src={logo} alt='taaza-logo'/>
                </div>
                <div className='home-welcome-title-cont'>
                    Welcome to Taaza - Where Freshness Meets Flavor!
                </div>
                <div className='home-welcome-text-cont'>
                    <p>
                        At Taaza, we're on a mission to revolutionize the way you experience Indian street food. Our food truck is not just a place to grab a quick bite – it's a culinary journey that transports you to the vibrant streets of India, where every corner is filled with the aroma of spices and the sizzle of delicious snacks. With a commitment to freshness, quality, and authenticity, Taaza is your go-to destination for mouthwatering flavors and unforgettable dining experiences. Explore our menu, follow our journey, and join us in celebrating the rich tapestry of Indian cuisine.
                    </p>
                </div>
                <div className='home-about-title-cont'>
                    About Us
                </div>
                <hr/>
                <div className='home-about-text-cont'>
                    <p>
                        Taaza is the brainchild of a team of passionate food enthusiasts who share a deep love for Indian street food. Inspired by the sights, sounds, and flavors of our homeland, we set out to bring the essence of Indian street food to the streets of Dallas. Our journey began with a simple idea: to create a food truck that not only serves delicious food but also tells a story – a story of tradition, innovation, and the relentless pursuit of culinary excellence. From our carefully curated menu to our warm and welcoming service, every aspect of Taaza reflects our commitment to quality, authenticity, and customer satisfaction. Join us on this delicious adventure and experience the magic of Taaza for yourself.
                    </p>
                </div>
                <div className='home-menu-title-cont'>
                    Our Menu
                </div>
                <hr/>
                <div className='home-menu-text-cont'>
                    <p>
                        Prepare your taste buds for a culinary adventure like no other! Our menu is a celebration of the diverse flavors and textures of Indian street food, with a modern twist that's sure to delight your palate. From crispy samosas to spicy noodles, creamy kulfis to refreshing lassis, our handcrafted dishes are made using only the finest ingredients and traditional cooking techniques. Whether you're a die-hard foodie or a casual diner looking for something new, Taaza has something for everyone. Explore our menu and discover your new favorite dish today!
                    </p>
                </div>
                <a href={pdf} className='home-menu-link'>Visit Our Menu Here</a>
                <div className='home-order-btn-cont'>
                    <a href='/order' className='home-order-btn'>Order Now&nbsp;
                    <span>&gt;</span>
                    <span>&gt;</span>
                    <span>&gt;</span>
                    </a>
                </div>
            </div>
        </div>
    );
}