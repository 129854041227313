import '../Styles/Login.css';
import { useState } from 'react';
import axint from '../ax_interceptor';
import loginBg from '../Media/AdobeStock_566538453.jpeg'

export default function Login(){

    const [creds,setCreds] = useState({
        username:"",
        password:""
    })
    const [showPswd,setShowPswd] = useState(false);

    function submit(e){
        e.preventDefault();
        axint.post("/login/",
                    creds,
                    {headers:{"Content-Type":"application/json"}},
                    {withCredentials:true})
            .then((res)=>{
                localStorage.clear();
                localStorage.setItem("tan",res.data.tan);
                localStorage.setItem("trn",res.data.trn);
                window.location.href = "/";
            })
            .catch((err)=>{
                console.log(err);
                alert("Try again");
            });
    }
    
    return(
        <div className='login-main-cont'>
            <img src={loginBg} alt='login-bg'/>
            <form onSubmit={submit} className='login-form'>
                <input name='username'
                type='text'
                placeholder='Email / Mobile No.'
                value={creds.username}
                onChange={(e)=>setCreds({...creds,username:e.target.value})}
                required/>
                
                <input name='password'
                type={showPswd ? 'text' : 'password'}
                placeholder='Password'
                value={creds.password}
                onChange={(e)=>setCreds({...creds,password:e.target.value})}
                required/>

                <div className='show-pswd-cont'>
                    <input type='checkbox' id='show-pswd' onChange={(e)=>{setShowPswd(e.target.checked)}}/>
                    <label htmlFor='show-pswd'>Show Password</label>
                </div>

                <input type='submit' value='Log in'/>

                <a href='/forgot-pswd'>Forgot Password?</a>
                <hr/>
                <a href='/register' style={{fontWeight:500}}>Create account</a>
            </form>
        </div>
    );
}