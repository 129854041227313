import '../Styles/Order.css';
import {useEffect,useState} from 'react'
import axint from '../ax_interceptor';
import Loading from './Loading';
import punugulu from '../Media/punugulu.jpg'

export default function Order(){

    const [isLoading,setIsLoading] = useState(true);
    const [items,setItems] = useState();
    const [activeCatg,setActiveCatgCatg] = useState();
    const [itemCount,setItemCount] = useState({});

    useEffect(()=>{
        // axint.get('/getitems/',
        //     {headers: {'Content-Type': 'application/json'}},  
        //     {withCredentials: true})
        // .then((res)=>{
        //     setItems(res.data);
        //     setActiveCatgCatg(Object.keys(res.data)[0])
        //     setIsLoading(false);
        // })
        // .catch((err)=>{
        //     console.log(err);
        // });
    },[])

    return(
        // isLoading?
        // <Loading/> :
        // (<div className='order-main-cont'>
        //     <div className='order-catg-cont'>
        //         {Object.keys(items).map((catg)=>(
        //             <div key={catg} 
        //             onClick={()=>{setActiveCatgCatg(catg)}}
        //             className={(catg === activeCatg ? 'order-active-catg' : '')}>{catg}</div>
        //         ))}
        //     </div>
        //     <div className='order-item-cont'>
        //     {(items[activeCatg]).map((item)=>(
        //         <div className='order-item'>
        //             <img src={punugulu} alt='sample'/>
        //             <div className='order-item-details'>
        //                 <div className='order-item-name'>{item.name}</div>
        //                 <div className='order-item-price'>${item.price}</div>
        //             </div>
        //             <div className='order-item-count-wrapper'>
        //                 {true ?
        //                 <div className='order-item-plus' onClick={()=>{}}>+</div> :
        //                 <div className='order-item-counter'>
        //                     <button type="button" class="sub">-</button>
        //                     <input type="text" id="order-item-count-field" value="0" class="field" />
        //                     <button type="button" class="add">+</button>
        //                 </div>
        //                 }
        //             </div>
        //         </div>
        //     ))}
        //     </div>
        // </div>)
        <div className='order-placeholder'>
            <span>
                Coming soon...<br/>
                In the meanwhile, you can contact us <a href='/contact-us'>here</a>
            </span>
        </div>
    );
}